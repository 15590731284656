import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginForm } from '../models/login.model';
import { AuthService } from '../services/auth/auth.service';
import { SessionService } from './state/session.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  isInvalidResponse: Boolean = false;

  loginForm: LoginForm = this.fb.nonNullable.group({
    userName: ['', [Validators.required, Validators.email,]],
    password: ['', [Validators.required]]
  })

  get userName() {
    return this.loginForm.get('userName') as FormControl;
  }

  get password() {
    return this.loginForm.get('password') as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private sessionService: SessionService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loginForm.setValue({
      userName: environment.authUserName,
      password: environment.authUserPassword
    });
    // this.submit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  submit(): void {
    const userName = this.userName.value;
    const password = this.password.value;

    this.subscriptions.push(
      this.auth.login(userName, password).pipe(
        catchError(err => {
          console.error(err);
          this.isInvalidResponse = true;
          return EMPTY;
        }),
        tap(response => {
          localStorage.setItem('access_token', `${response.token_type} ${response.access_token}`);
          this.sessionService.setSession();
          this.router.navigate(['launchpad']);
        })
      ).subscribe()
    );
  }

}
