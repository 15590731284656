import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { InterApplicationBusService } from '../services/openfin/inter-application-bus.service';
import { WindowService } from '../services/openfin/window.service';
import { environment } from 'src/environments/environment';
import { fin } from "@openfin/core";

@Component({
  selector: 'app-create-manifest',
  templateUrl: './create-manifest.component.html',
  styleUrls: ['./create-manifest.component.scss']
})
export class CreateManifestComponent implements OnInit {
  hostUrl: string;
  pageUrl: string;
  manifestName: string;
  formData: any[];

  constructor(
    private cdr: ChangeDetectorRef,
    private iab: InterApplicationBusService,
    private win: WindowService) { }

  ngOnInit() {
    this.iab.subscribe('*', 'createManifest', (response: any) => {
      if (environment.loggingEnabled) {
        console.log('Recieved Data From * on createManifest', response);
      }
      this.hostUrl = response.hostUrl;
      this.pageUrl = response.pageUrl;
      this.manifestName = response.manifestName;
      this.formData = response.fields;
      this.cdr.detectChanges();
      fin.Window.getCurrent().then(win => win.bringToFront());
    });
  }

  save() {
    this.iab.publish('saveManifest', {
      hostUrl: window.location.host,
      pageUrl: window.location.origin + window.location.pathname,
      manifestName: '',
      fields: this.formData
    });
  }

  minimize(): void {
    this.win.minimize();
  }

  close(): void {
    this.win.close();
  }
}
