import { Guid } from 'guid-typescript';

export interface ChangePersona {
  ApplicationName: string;
  ApplyDataLocalisation: boolean;
  CountryCode: string;
  DataLocalisationCondition: string;
  DataOwningCountryCode: string;
  DataSubjectCommonName: string;
  DataUsageId: number;
  DataValueConsistency: string;
  IsDataConsistent: boolean;
  JobType: string;
  Json: string;
  ManifestName: string;
  ManifestType: string;
  PreserveStringLength: boolean;
  ProtectNullValues: boolean;
  RequestItemId: string;
  RestrictedText: string;
  RunAsUserUniqueName: string;
  SnapshotDate: Date;
  UseCache: boolean;
  UseRestrictedText: boolean;
  UseSelfIdentifier: boolean;
}

export function createChangePersona({
  ApplicationName = 'Openfin',
  ApplyDataLocalisation = false,
  CountryCode = 'GB',
  DataLocalisationCondition = 'Use_CountryCode',
  DataOwningCountryCode = 'GB',
  DataSubjectCommonName = '',
  DataUsageId = 0,
  DataValueConsistency = 'Always_The_Same_With_Rotation',
  IsDataConsistent = true,
  JobType = 'Reconstruct',
  Json = '',
  ManifestName = '',
  ManifestType = 'Api',
  PreserveStringLength = false,
  ProtectNullValues = true,
  RequestItemId = Guid.create().toString(),
  RestrictedText = 'Restricted Access',
  RunAsUserUniqueName = '',
  SnapshotDate = new Date,
  UseCache = false,
  UseRestrictedText = false,
  UseSelfIdentifier = true
}: Partial<ChangePersona>) {
  return {
    ApplicationName,
    ApplyDataLocalisation,
    CountryCode,
    DataLocalisationCondition,
    DataOwningCountryCode,
    DataSubjectCommonName,
    DataUsageId,
    DataValueConsistency,
    IsDataConsistent,
    JobType,
    Json,
    ManifestName,
    ManifestType,
    PreserveStringLength,
    ProtectNullValues,
    RequestItemId,
    RestrictedText,
    RunAsUserUniqueName,
    SnapshotDate,
    UseCache,
    UseRestrictedText,
    UseSelfIdentifier
  } as ChangePersona;
}
