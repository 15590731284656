import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss']
})
export class HealthComponent implements OnInit {

  health: string;

  constructor() { }

  ngOnInit(): void {
    const data = {
      name: 'eXate + openFin',
      status: 'Healthy',
      errorMessage: '',
      components: []
    };
    this.health = JSON.stringify(data, undefined, 2);
  }

}
