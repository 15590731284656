import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { fin } from "@openfin/core";

@Injectable({
  providedIn: 'root'
})
export class InterApplicationBusService {

  constructor() { }

  // Publishes a message to all applications running on OpenFin Runtime that are subscribed to the specified topic.
  publish(topic: string, payload: any) {
    const data = this.verifyPayload(payload);

    fin.InterApplicationBus.publish(topic, data)
      .then(() => (environment.loggingEnabled) ? console.log('Data sent to * - ' + topic + ' : ' + data) : '')
      .catch((error: any) => (environment.loggingEnabled) ? console.error(error) : '');
  }

  // Sends a message to a specific application on a specific topic
  send(uuid: string, topic: string, payload: any) {
    const data = this.verifyPayload(payload);

    fin.InterApplicationBus.send({ uuid }, topic, data)
      .then(() => (environment.loggingEnabled) ? console.log('Data sent to ' + uuid + ' - ' + topic + ' : ' + data) : '')
      .catch((error: any) => (environment.loggingEnabled) ? console.error(error) : '');
  }

  // Subscribes to messages from the specified application (or "*") on the specified topic.
  subscribe(uuid: string, topic: string, listener?: any) {
    if (listener) {
      fin.InterApplicationBus.subscribe({ uuid }, topic, listener)
        .then(() => (environment.loggingEnabled) ? console.log('Subscribed to ' + uuid + ' for ' + topic) : '')
        .catch((error: any) => (environment.loggingEnabled) ? console.error(error) : '');
    } else {
      fin.InterApplicationBus.subscribe({ uuid }, topic, (response: any, senderUuid: any) => {
        if (environment.loggingEnabled) {
          console.log('Recieved Data From ' + senderUuid.uuid + ' on ' + topic, response);
          console.error('Request not implemented.');
        }
      })
        .then(() => (environment.loggingEnabled) ? console.log('Subscribed to ' + uuid + ' for ' + topic) : '')
        .catch((error: any) => (environment.loggingEnabled) ? console.error(error) : '');
    }
  }

  // Unsubscribes to messages from the specified application on the specified topic
  unsubscribe(uuid: string, topic: string) {
    fin.InterApplicationBus.unsubscribe({ uuid }, topic, () => (environment.loggingEnabled) ? console.log('Not receiving messages') : '')
      .then(() => (environment.loggingEnabled) ? console.log('Unsubscribed to ' + uuid + ' for ' + topic) : '')
      .catch((error: any) => (environment.loggingEnabled) ? console.error(error) : '');
  }

  // Verify Payload
  verifyPayload(payload: any) {
    if (typeof payload !== 'string') {
      return JSON.stringify(payload);
    } else {
      return payload;
    }
  }
}
