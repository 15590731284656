import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { createSession, Session } from './session.model';

export const InitialSessionState = {
  id: 0,
  username: '',
  email: '',
  accessToken: '',
  idToken: '',
  isLoggedIn: false,
  firmName: '',
  firmId: '',
  userFirmId: '',
  departmentId: '',
  zoneId: '',
  roles: [] as string[]
};

export function createInitialState(): Session {
  return InitialSessionState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'session',
  resettable: true
})
export class SessionStore extends Store<Session> {

  constructor() {
    super(createInitialState());
  }

  reset() {
    this.update(createInitialState());
  }

  updateSession(claims: any, accessToken: string, idToken: string) {
    this.update(createSession(
      {
        id: claims.sid,
        accessToken,
        idToken,
        isLoggedIn: true,
        username: claims.name,
        email: claims.email,
        userFirmId: claims.UserFirmId,
        firmId: claims.FirmId,
        firmName: claims.FirmName,
        zoneId: claims.ZoneId,
        departmentId: claims.FirmDepartmentId,
        roles: claims.role
      }
    ));
  }

}

