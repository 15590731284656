import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { createChangePersona } from '../change-persona/state/change-persona.model';
import { ChangePersonaQuery } from '../change-persona/state/change-persona.query';
import { Country } from '../country/state/country.model';
import { DataUsage } from '../data-usage/state/data-usage.model';
import { OpenfinPayload } from '../models/openfin-payload.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private changePersonaQuery: ChangePersonaQuery
  ) { }

  // Get Decrypted Data
  getDecryptedData(data: OpenfinPayload): Observable<any> {
    const req = createChangePersona(this.changePersonaQuery.getChangePersona());
    req.Json = JSON.stringify(data);

    const params = {
      Requests: [req]
    }
    return this.http.post(`${environment.host.api.apigator}/api/protect/json`, params);
  }

  // Get Countries in Scope
  getCountriesByFirmInScope(): Observable<Country[]> {
    const params = {
      inScope: 'true'
    }
    return this.http.get<Country[]>(`${environment.host.api.staticData}/api/country/firm-in-scope`, { params });
  }

  // Get Data Usage
  getDataUsageByFirmId(): Observable<DataUsage[]> {
    return this.http.get<DataUsage[]>(`${environment.host.api.staticData}/api/data-usage`);
  }
}
