export interface Session {
  id: number | string;
  username: string;
  email: string;
  accessToken: string;
  idToken: string;
  isLoggedIn: boolean;
  firmName: string;
  firmId: string;
  userFirmId: string;
  departmentId: string;
  zoneId: string;
  roles: string[];
}

export interface Login {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  scope: string;
}

export enum UserRoleEnum {
  GlobalAdministrator = 'global_administrator',
  FirmAdministrator = 'firm_administrator',
  SystemAdmin = 'sys_admin',
  IamEdit = 'iam_edit',

  VaultRead = 'vault_read',
  VaultEdit = 'vault_edit',

  ManifestRead = 'manifest_read',
  ManifestEdit = 'manifest_edit',
  ManifestApprover = 'manifest_approver',
  ApiSuperAdmin = 'api_super_admin',

  RuleRead = 'rule_read',
  RuleEdit = 'rule_edit',
  RuleApprover = 'rule_approver',

  StaticEdit = 'static_edit',

  ThirdPartyManager = 'third_party_manager',
  InkbombApprover = 'inkbomb_approver',
}

export function createSession({
  id = '',
  username = '',
  email = '',
  accessToken = '',
  idToken = '',
  isLoggedIn = true,
  firmName = '',
  firmId = '',
  userFirmId = '',
  departmentId = '',
  zoneId = '',
  roles = []
}: Partial<Session>) {
  return {
    id,
    username,
    email,
    accessToken,
    idToken,
    isLoggedIn,
    firmName,
    firmId,
    userFirmId,
    departmentId,
    zoneId,
    roles
  } as Session;
}
