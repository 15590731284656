import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Login } from 'src/app/login/state/session.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl: string;
  currentUser: object;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.host.auth.identityServer;
  }

  getAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  login(userName: string, password: string): Observable<Login> {
    // Set Headers
    let headers = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded')

    // Set Config
    const config = {
      'client_id': environment.authClientId,
      'client_secret': environment.authClientSecret,
      'grant_type': environment.authGrantType,
      'username': userName,
      'password': password,
    };
    const body = new URLSearchParams(config).toString()

    return this.http.post<Login>(
      `${this.baseUrl}/connect/token`, body, { headers }
    );
  }

  logout(): void {
    localStorage.removeItem('access_token')
  }
}
