import { Injectable } from '@angular/core';
import { SessionStore, InitialSessionState } from './session.store';
import { SessionQuery } from './session.query';
import { Session } from './session.model';
import jwt_decode from 'jwt-decode';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class SessionService {

  constructor(
    private store: SessionStore,
    private query: SessionQuery,
    private authService: AuthService
  ) { }

  getSession(): Session {
    const session = this.query.getSession();
    if (session === InitialSessionState) {
      this.setSession();
      return this.query.getSession();
    }

    return session;
  }

  setSession(): void {
    var accessToken = this.authService.getAccessToken();
    var claims = jwt_decode(accessToken);
    const idToken = '';

    if (accessToken && claims) {
      this.store.updateSession(claims, accessToken, idToken);
    }
  }

  reset(): void {
    this.store.reset();
  }

}
