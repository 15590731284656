import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  info: string;

  constructor() { }

  ngOnInit(): void {
    this.subscriptions.push(
      interval(1000).pipe(
        tap(() => this.getVaultInfo())
      ).subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getVaultInfo(): void {
    const data = {
      ANGULAR_ENVIRONMENT: environment.environment,
      BRANCH_NAME: environment.branchName,
      GIT_COMMIT: environment.gitCommit,
      BUILD_DATE: new Date(environment.buildDate),
      UP_TIME: 'N/A'
    };
    this.info = JSON.stringify(data, undefined, 2);
  }

  generateUpTime(startTime: string) {
    let msec = new Date().getTime() - new Date(startTime).getTime();
    const hours = Math.floor(msec / 1000 / 60 / 60);
    msec -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(msec / 1000 / 60);
    msec -= minutes * 1000 * 60;
    const seconds = Math.floor(msec / 1000);
    msec -= seconds * 1000;
    
    // eslint-disable-next-line max-len
    return `${hours.toString().padStart(2, '0')} Hours ${minutes.toString().padStart(2, '0')} Minutes ${seconds.toString().padStart(2, '0')} Seconds`;
  }

}
