import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Country } from '../country/state/country.model';
import { CountryQuery } from '../country/state/country.query';
import { CountryService } from '../country/state/country.service';
import { DataUsage } from '../data-usage/state/data-usage.model';
import { DataUsageQuery } from '../data-usage/state/data-usage.query';
import { DataUsageService } from '../data-usage/state/data-usage.service';
import { SessionQuery } from '../login/state/session.query';
import { ChangePersonaForm } from '../models/change-persona-form.model';
import { InterApplicationBusService } from '../services/openfin/inter-application-bus.service';
import { ChangePersonaQuery } from './state/change-persona.query';
import { ChangePersonaService } from './state/change-persona.service';

@Component({
  selector: 'app-change-persona',
  templateUrl: './change-persona.component.html',
  styleUrls: ['./change-persona.component.scss']
})
export class ChangePersonaComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  countries$: Observable<Country[]>;
  dataUsage$: Observable<DataUsage[]>;

  changePersonaForm: ChangePersonaForm = this.fb.nonNullable.group({
    IsDataRedactionEnable: [false, Validators.required],
    ApplyDataLocalisation: [false, Validators.required],
    CountryCode: ['GB', Validators.required],
    DataUsageId: [0, Validators.required],
    ProtectNullValues: [true, Validators.required],
    IsRunAsUserEnable: [false, Validators.required],
    RestrictedText: ['Restricted Access'],
    RunAsUserUniqueName: ['', [(control: AbstractControl) => this.ValidateEmailFormat(control)]],
    UseRestrictedText: [false, Validators.required],
  });

  get IsDataRedactionEnable() {
    return this.changePersonaForm.get('IsDataRedactionEnable') as FormControl;
  }

  get ApplyDataLocalisation() {
    return this.changePersonaForm.get('ApplyDataLocalisation') as FormControl;
  }

  get CountryCode() {
    return this.changePersonaForm.get('CountryCode') as FormControl;
  }

  get DataUsageId() {
    return this.changePersonaForm.get('DataUsageId') as FormControl;
  }

  get ProtectNullValues() {
    return this.changePersonaForm.get('ProtectNullValues') as FormControl;
  }

  get RestrictedText() {
    return this.changePersonaForm.get('RestrictedText') as FormControl;
  }

  get IsRunAsUserEnable() {
    return this.changePersonaForm.get('IsRunAsUserEnable') as FormControl;
  }

  get RunAsUserUniqueName() {
    return this.changePersonaForm.get('RunAsUserUniqueName') as FormControl;
  }

  get UseRestrictedText() {
    return this.changePersonaForm.get('UseRestrictedText') as FormControl;
  }

  ValidateEmailFormat(control: AbstractControl): { [key: string]: any } | null {
    if (control.value !== '') {
      const emailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      return emailRegExp.test(control.value) ? null : { message: 'Invalid Email !' };
    }
    return null;
  }

  constructor(
    private fb: FormBuilder,
    private sessionQuery: SessionQuery,
    private countryQuery: CountryQuery,
    private countryService: CountryService,
    private dataUsageQuery: DataUsageQuery,
    private dataUsageService: DataUsageService,
    private changePersonaQuery: ChangePersonaQuery,
    private changePersonaService: ChangePersonaService,
    private iab: InterApplicationBusService
  ) { }

  ngOnInit() {
    // Get Change Persona Config
    const data = this.changePersonaQuery.getChangePersona()
    this.changePersonaForm.setValue({
      IsDataRedactionEnable: environment.dataRedaction,
      ApplyDataLocalisation: data.ApplyDataLocalisation,
      CountryCode: data.CountryCode,
      DataUsageId: data.DataUsageId,
      ProtectNullValues: data.ProtectNullValues,
      RestrictedText: data.RestrictedText,
      IsRunAsUserEnable: data.RunAsUserUniqueName ? true : false,
      RunAsUserUniqueName: data.RunAsUserUniqueName,
      UseRestrictedText: data.UseRestrictedText
    });

    // Get Countries
    this.countries$ = this.countryQuery.selectAll();
    this.subscriptions.push(
      this.countryService.get().subscribe()
    );

    // Get Data Usage
    this.dataUsage$ = this.dataUsageQuery.selectAll();
    this.subscriptions.push(
      this.dataUsageService.get().subscribe()
    );

    // Observe Run as user
    this.subscriptions.push(
      combineLatest([
        this.sessionQuery.selectEmail().pipe(
          startWith(this.sessionQuery.getEmail())
        ),
        this.IsRunAsUserEnable.valueChanges
      ]).pipe(
        tap(([userEmail, isRunAsUserEnable]) => {
          if (isRunAsUserEnable) {
            this.RunAsUserUniqueName.setValue(userEmail);
            this.RunAsUserUniqueName.setValidators([
              Validators.required,
              (control: AbstractControl) => this.ValidateEmailFormat(control)
            ]);
          } else {
            this.RunAsUserUniqueName.setValue('');
            this.RunAsUserUniqueName.setValidators(null);
          }
          this.RunAsUserUniqueName.updateValueAndValidity();
        })
      ).subscribe()
    );

    // Observe Apply data localisation
    this.subscriptions.push(
      this.ApplyDataLocalisation.valueChanges.pipe(
        startWith(this.ApplyDataLocalisation.value),
        tap(applyDataLocalisation => {
          if (!applyDataLocalisation) {
            this.CountryCode.setValue('GB');
            this.CountryCode.disable();
          } else {
            this.CountryCode.enable();
          }
          this.CountryCode.updateValueAndValidity();
        })
      ).subscribe()
    );

    // Observe Restrict text
    this.subscriptions.push(
      this.UseRestrictedText.valueChanges.pipe(
        startWith(this.UseRestrictedText.value),
        tap(useRestrictedText => {
          this.RestrictedText.setValue(data.RestrictedText);
          if (useRestrictedText) {
            this.RestrictedText.setValidators([Validators.required]);
          } else {
            this.RestrictedText.setValidators(null);
          }
          this.RestrictedText.updateValueAndValidity();
        })
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  savePersonaChanges(): void {
    this.changePersonaService.setChangePersona({
      ApplyDataLocalisation: this.ApplyDataLocalisation.value,
      CountryCode: this.CountryCode.value,
      DataUsageId: parseInt(this.DataUsageId.value, 10),
      ProtectNullValues: this.ProtectNullValues.value,
      RestrictedText: this.RestrictedText.value,
      RunAsUserUniqueName: this.RunAsUserUniqueName.value,
      UseRestrictedText: this.UseRestrictedText.value
    });
    this.changeDataRedaction(this.IsDataRedactionEnable.value);
    this.changeRestrictedTextValue(this.RestrictedText.value);
    this.changePersonaForm.markAsPristine();

    this.iab.publish('forcedReload', null);
  }

  changeRestrictedTextValue(restrictedText: string): void {
    environment.encryptedTextMockValue = restrictedText;
  }

  changeDataRedaction(isDataRedactionEnable: boolean): void {
    environment.dataRedaction = isDataRedactionEnable;
  }
}
