export interface OpenfinPayload {
  openfinPayload: OpenfinPayloadData[];
}

export interface OpenfinPayloadData {
  EncryptedValue: string;
  DecryptedValue: string;
  IsError: boolean;
}

export function createOpenfinPayload({
  openfinPayload = [] as OpenfinPayloadData[],
}: Partial<OpenfinPayload>) {
  return {
    openfinPayload
  } as OpenfinPayload;
}

export function createOpenfinPayloadData({
  EncryptedValue = '',
  DecryptedValue = ''
}: Partial<OpenfinPayloadData>) {
  return {
    EncryptedValue,
    DecryptedValue
  } as OpenfinPayloadData;
}