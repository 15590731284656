import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DataUsageStore, DataUsageState } from './data-usage.store';

@Injectable({ providedIn: 'root' })
export class DataUsageQuery extends QueryEntity<DataUsageState> {

  constructor(protected store: DataUsageStore) {
    super(store);
  }

}
