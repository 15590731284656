import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DataUsage } from './data-usage.model';

export interface DataUsageState extends EntityState<DataUsage> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ 
  name: 'data-usage',
  idKey: 'DataUsageId',
  resettable: true
})
export class DataUsageStore extends EntityStore<DataUsageState> {

  constructor() {
    super();
  }

}
