<app-top-bar [transparentBackground]="true"></app-top-bar>

<div>
    <div id="apps" class="wrapper">
        <div id="app-all">

            <!-- <div id="hubspot-app" class="launchpad_app" title="Reconstruct Duco" (click)="launchDuco()">
                <i class="fab fa-dyalog"></i>
                <p>Duco</p>
            </div> -->

            <!-- <div id="databricks-app" class="launchpad_app" title="Reconstruct Databricks" (click)="launchDatabricks()">
                <i class="fas fa-layer-group"></i>
                <p>Databricks</p>
            </div> -->

            <div id="denodo-app" class="launchpad_app" title="Reconstruct Denodo" (click)="launchDenodo()">
              <i class="fas fa-layer-group"></i>
              <p>Denodo</p>
            </div>

            <!-- <div id="powerBI-app" class="launchpad_app" title="Reconstruct Power BI" (click)="launchPowerBI()">
                <i class="fas fa-signal"></i>
                <p>Power BI</p>
            </div> -->

            <div id="Dremio-app" class="launchpad_app" title="Reconstruct Dremio" (click)="launchDremio()">
                <object type="image/svg+xml" data="assets/images/dremio-icon.svg">
                    <img src="assets/images/dremio-icon.svg" />
                </object>
                <p>Dremio</p>
            </div>

            <!-- <div id="hubspot-app" class="launchpad_app" title="Reconstruct Hubspot" (click)="launchHubspot()">
                <i class="fab fa-hubspot"></i>
                <p>Hubspot</p>
            </div>

            <div id="twitter-app" class="launchpad_app" title="Reconstruct Twitter" (click)="launchTwitter()">
                <i class="fab fa-twitter"></i>
                <p>Twitter</p>
            </div> -->

            <div id="website-app" class="launchpad_app" title="Reconstruct Website" (click)="launchWebsite()">
                <i class="fas fa-globe"></i>
                <p>eXate</p>
            </div>

            <!-- <div id="website-app" class="launchpad_app" title="Reconstruct Demo App" (click)="launchDemoApp()">
                <i class="fas fa-globe"></i>
                <p>Demo App</p>
            </div> -->

            <div id="settings-app" title="Profile" (click)="launchChangePersona()" class="launchpad_app">
                <i class="fas fa-user-cog"></i>
                <p>Profile</p>
            </div>

        </div>
    </div>
</div>

<button id="logout" (click)="logout()">
    <i class="fas fa-power-off"></i>
</button>


<app-footer></app-footer>
