import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { fin } from "@openfin/core";

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor() { }

  launch(alias: string): Promise<string> {
    return new Promise((resolve, reject) => {
      fin.System.launchExternalProcess({
        alias,
        lifetime: 'window',
        listener: (result: any) => (environment.loggingEnabled) ? console.error('External app terminated :', result.uuid) : '',
      })
        .then((payload: any) => {
          if (environment.loggingEnabled) {
            console.log(`${alias} launched : ${payload.uuid}`);
          }
          resolve(payload.uuid);
        })
        .catch((error: any) => reject(error));
    });
  }
}
