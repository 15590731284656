import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Guid } from 'guid-typescript';
import { ChangePersona } from './change-persona.model';

export const InitialChangePersonaState = {
  ApplicationName: 'Openfin',
  ApplyDataLocalisation: false,
  CountryCode: '',
  DataLocalisationCondition: 'Use_CountryCode',
  DataOwningCountryCode: 'GB',
  DataSubjectCommonName: '',
  DataUsageId: 0,
  DataValueConsistency: 'Always_The_Same_With_Rotation',
  IsDataConsistent: true,
  JobType: '',
  Json: '',
  ManifestName: '',
  ManifestType: 'Api',
  PreserveStringLength: false,
  ProtectNullValues: true,
  RequestItemId: Guid.create().toString(),
  RestrictedText: 'Restricted Access',
  RunAsUserUniqueName: '',
  SnapshotDate: new Date,
  UseCache: false,
  UseRestrictedText: false,
  UseSelfIdentifier: true
};

export function createInitialState(): ChangePersona {
  return InitialChangePersonaState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'change-persona',
  resettable: true
})
export class ChangePersonaStore extends Store<ChangePersona> {

  constructor() {
    super(InitialChangePersonaState);
  }

  reset() {
    this.update(createInitialState());
  }

  updateChangePersona(data: Partial<ChangePersona>) {
    this.update(data);
  }

}
