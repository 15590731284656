import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { Country } from './country.model';
import { CountryQuery } from './country.query';
import { CountryStore } from './country.store';

@Injectable({ providedIn: 'root' })
export class CountryService {

  constructor(
    protected store: CountryStore,
    protected query: CountryQuery,
    protected http: ApiService
  ) { }

  get(): Observable<Country[]> {
    return this.query.selectHasCache().pipe(
      switchMap(hasCache => {
        const request = this.http.getCountriesByFirmInScope().pipe(
          catchError(error => {
            // this.messageService.handleError('error getting countries:', error);
            console.error('error getting countries:', error);
            return EMPTY;
          }),
          tap(response => this.store.set(response))
        );
        return hasCache ? of([]) : request;
      }), delay(0)
    );
  }

}
