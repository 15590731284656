<div id="secowserModal" class="secowser-modal">     
    <div class="secowser-modal-content">
        <div class="exate-modal-head">
            <div>
                <img src="../../assets/images/EXA-Logo.png"/>
                <h1>Form Configuration</h1> 
            </div>
            <span id="secowserModalClose" (click)="close()" class="secowser-modal-close">&times;</span>
        </div>
        <div class="exate-modal-body">
            <div class="exate-title">
                <div class="new_user_cb">
                    <a>New User</a>
                    <div class="exate-toggle">
                        <label class="exate-switch">
                            <input id="checkAll" type="checkbox" data-check-all="">
                            <span class="exate-slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
            
            <div class="widget">
                <div class="widget_head">
                    <div>
                        <h4 class="font-weight-bold">Application Base URL: {{ hostUrl }}</h4>
                        <p>Page URL: {{ pageUrl }}</p>
                    </div>
                </div>
                <table class="exate-table">
                    <thead class="exate-thead">
                        <tr class="exate-tr">
                            <th class="exate-th">Element</th>
                            <th class="exate-th">Value</th>
                            <th class="exate-th">Attribute Type</th>
                            <th class="exate-th">Anonymize</th>
                        </tr>
                    </thead>
                    <tbody class="exate-tbody">
                        <tr *ngFor="let item of formData;" class="exate-tr">
                            <td data-column="Element" class="exate-td text-left">{{ item.name }}</td>
                            <td data-column=""  class="exate-td text-left">{{ item.value }}</td>
                            <td data-column="Attribute Type" class="exate-td">
                                <div class="exate-select">
                                    <select name="slct" id="slct">
                                        <option>FN</option>
                                        <option value="1">Sample</option>
                                        <option value="2">FN 2</option>
                                        <option value="3">Test</option>
                                    </select>
                                </div>
                            </td>
                            <td data-column="Anonymize" class="exate-td">
                                <div class="exate-toggle">
                                    <!-- <span>NO</span> -->
                                    <label class="exate-switch">
                                        <input id="checkAll" type="checkbox" data-check-all="">
                                        <span class="exate-slider round"></span>
                                    </label>
                                    <!-- <span>YES</span> -->
                                </div>
                            </td>
                        </tr>
                        <!-- <tr class="exate-tr">
                            <td data-column="Element" class="exate-td">Input 2</td>
                            <td data-column=""  class="exate-td">sample@gmail.com</td>
                            <td data-column="Attribute Type" class="exate-td">
                                <div class="exate-select">
                                    <select name="slct" id="slct">
                                        <option>FN</option>
                                        <option value="1">Sample</option>
                                        <option value="2">FN 2</option>
                                        <option value="3">Test</option>
                                    </select>
                                </div>
                            </td>
                            <td data-column="Anonymize" class="exate-td">
                                <div class="exate-toggle">
                                    <label class="exate-switch">
                                        <input id="checkAll" type="checkbox" data-check-all="">
                                        <span class="exate-slider round"></span>
                                    </label>
                                </div>
                            </td>
                        </tr> -->
                    </tbody>
                </table>
            </div>

            <button class="exate-btn-save" (click)="save()">
                <i class="fa fa-check exate-fa-check"></i>
                    SAVE CHANGES
            </button>
        </div>
    </div>
</div>
    