import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CountryService } from '../country/state/country.service';
import { DataUsageService } from '../data-usage/state/data-usage.service';
import { AuthService } from '../services/auth/auth.service';
import { InterApplicationBusService } from '../services/openfin/inter-application-bus.service';
import { WindowService } from '../services/openfin/window.service';

@Component({
  selector: 'app-launchpad',
  templateUrl: './launchpad.component.html',
  styleUrls: ['./launchpad.component.scss']
})
export class LaunchpadComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  commonPreloadScripts: { url: string }[]

  constructor(
    private iab: InterApplicationBusService,
    private win: WindowService,
    private router: Router,
    private countryService: CountryService,
    private dataUsageService: DataUsageService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.commonPreloadScripts = [
      {
        url: location.origin + '/assets/js/openfin/Cipher.js'
      },
      {
        url: location.origin + '/assets/js/openfin/InterApplicationBus.js'
      },
      {
        url: location.origin + '/assets/js/openfin/InterceptHttpRequest.js'
      },
      {
        url: location.origin + '/assets/js/openfin/Logging.js'
      },
      {
        url: location.origin + '/assets/js/openfin/MutationObserver.js'
      },
      {
        url: location.origin + '/assets/js/openfin/RequestHandler.js'
      },
      {
        url: location.origin + '/assets/js/openfin/Environment.js'
      },
      {
        url: location.origin + '/assets/js/openfin/Init.js'
      }
    ];

    // Fetch Countries
    this.subscriptions.push(
      this.countryService.get().subscribe()
    );

    // Fetch Data Usage
    this.subscriptions.push(
      this.dataUsageService.get().subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  launchDuco() {
    const winOption = {
      name: 'Reconstruct Duco',
      url: 'https://ecosystem.ducocube.com',
      preloadScripts: this.commonPreloadScripts
    };

    this.win.createWindow(winOption);
  }

  launchHubspot() {
    const winOption = {
      name: 'Reconstruct Hubspot',
      url: 'https://app.hubspot.com/',
      preloadScripts: [
        ...this.commonPreloadScripts,
        // {
        //   url: location.origin + '/assets/js/hubspot/CreateManifest.js'
        // }
      ],
    };

    this.win.createWindow(winOption);
  }

  launchDatabricks() {
    const winOption = {
      name: 'Reconstruct Databricks',
      url: 'https://community.cloud.databricks.com/',
      preloadScripts: this.commonPreloadScripts
    };

    this.win.createWindow(winOption);
  }

  launchDenodo() {
    const winOption = {
      name: 'Reconstruct Denodo',
      url: 'https://denodo.test.exate.co/denodo-design-studio/',
      preloadScripts: this.commonPreloadScripts
    };

    this.win.createWindow(winOption);
  }

  launchPowerBI() {
    const winOption = {
      name: 'Reconstruct Power BI',
      url: 'https://app.powerbi.com/home',
      preloadScripts: this.commonPreloadScripts
    };

    this.win.createWindow(winOption);
  }

  launchTwitter() {
    const winOption = {
      name: 'Reconstruct Twitter',
      url: 'https://twitter.com/exatetech?lang=en',
      preloadScripts: this.commonPreloadScripts
    };

    this.win.createWindow(winOption);
  }

  launchWebsite() {
    const winOption = {
      name: 'Reconstruct Website',
      url: 'https://www.exate.com',
      preloadScripts: this.commonPreloadScripts
    };

    this.win.createWindow(winOption);
  }

  launchDremio() {
    const winOption = {
      name: 'Reconstruct Dremio',
      url: 'https://app.dremio.cloud',
      preloadScripts: this.commonPreloadScripts
    };

    this.win.createWindow(winOption);
  }

  launchDemoApp() {
    const winOption = {
      name: 'Demo Data Upload',
      url: 'http://127.0.0.1:3000',
      preloadScripts: this.commonPreloadScripts
    };
    this.win.createWindow(winOption);
  }

  launchChangePersona() {
    this.router.navigate(['change-persona']);

    // const winOption = {
    //   name: 'Change Persona',
    //   url: 'http://localhost:4200/change-persona',
    //   frame: false,
    //   cornerRounding: {
    //     width: 5,
    //     height: 5
    //   }
    // };

    // this.win.createWindow(winOption);
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['login']);
  }
}
