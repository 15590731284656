import { Injectable } from '@angular/core';
import OpenFin, { fin } from "@openfin/core";
import { _Window } from '@openfin/core/src/api/window';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor() { }

  async getCurrentWindowUuid(): Promise<string> {
    const win = await fin.Window.getCurrent();
    return win.identity.uuid;
  }

  async createWindow(winOption: OpenFin.WindowCreationOptions): Promise<_Window> {
    return await fin.Window.create(winOption);
  }

  async minimize(): Promise<void> {
    const win = await fin.Window.getCurrent();
    win.minimize();
  }

  async close(): Promise<void> {
    const win = await fin.Window.getCurrent();
    win.close();
  }
}
