<app-top-bar title="Login"></app-top-bar>

<div id="appSettings" class="wrapper">
    <form class="form-horizontal" role="form" [formGroup]="loginForm">

        <div class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label text-light">
                User Name
            </label>
            <div class="col-sm-6 input-group-sm">
                <input type="email" class="form-control darkinput" formControlName="userName">
                <ul *ngIf="userName.invalid" class="parsley-errors-list filled">
                    <li *ngIf="userName.errors.email" class="parsley-type">
                        Invalid User Name
                    </li>
                    <li *ngIf="userName.touched && !userName.errors.email" class="parsley-type">
                        User Name is required
                    </li>
                </ul>
            </div>
        </div>

        <div class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label text-light">
                Password
            </label>
            <div class="col-sm-6 input-group-sm">
                <input (keydown.enter)="loginForm.valid ? submit() : ''" type="password" class="form-control darkinput" formControlName="password">
                <ul *ngIf="password.invalid" class="parsley-errors-list filled">
                    <li *ngIf="password.errors.message" class="parsley-type">
                        {{password.errors.message}}</li>
                    <li *ngIf="password.touched && !password.errors.message" class="parsley-type">
                        Password is required
                    </li>
                </ul>
            </div>
        </div>

    </form>

    <p *ngIf="isInvalidResponse" class="row px-3 error-text">
        Username or password is incorrect !
    </p>
    <div class="form-group row px-3 mt-5">
        <div class="col">
          <button type="button" class="btn btn-save savebtn btn-lg mb-2" [disabled]="loginForm.invalid"
              (click)="submit()">
              <i class="fa fa-check fa-darkicon bg-black"></i>Login
          </button>
  
          <!-- <button type="button" class="btn btn-save savebtn btn-lg mb-2" [routerLink]="'/launchpad'">
              <i class="fa fa-arrow-left fa-darkicon bg-black"></i>Go back
          </button> -->
        </div>
    </div>
</div>

<app-footer></app-footer>