<div [ngClass]="{'bg-gray': !transparentBackground}" class="openfin-top-bar">
  <div class="exate-logo">
    <img src="../../assets/images/EXA-Logo-WT.png">
  </div>
  <div class="openfin-top-bar-drag">
    <span class="openfin-top-bar-title">
      {{ title }}
    </span>
  </div>
  <div class="exate-icons-wrapper">
    <ul>
      <li title="Minimise" (click)="minimize()"><i class="fa fa-minus exate-icons"></i></li>
      <li title="Close" (click)="close()"><i class="fa fa-times exate-icons icon-time"></i></li>
    </ul>
  </div>
</div>