import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ChangePersona } from './change-persona.model';
import { ChangePersonaStore } from './change-persona.store';

@Injectable({ providedIn: 'root' })
export class ChangePersonaQuery extends Query<ChangePersona> {

  constructor(
    protected store: ChangePersonaStore
  ) {
    super(store);
  }

  getChangePersona(): ChangePersona {
    return this.getValue();
  }

}
