import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SessionStore } from './session.store';
import { Observable } from 'rxjs';
import { Session } from './session.model';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<Session> {

  constructor(
    protected store: SessionStore
  ) {
    super(store);
  }

  getSession(): Session {
    return this.getValue();
  }

  selectUsername(): Observable<string> {
    return this.select(state => state.username);
  }

  selectEmail(): Observable<string> {
    return this.select(state => state.email);
  }

  getEmail(): string {
    return this.getValue().email;
  }

  selectFirmName(): Observable<string> {
    return this.select(state => state.firmName);
  }

  selectFirmId(): Observable<string> {
    return this.select(state => state.firmId);
  }

  selectUserFirmId(): Observable<string> {
    return this.select(state => state.userFirmId);
  }

  selectRoles(): Observable<string[]> {
    return this.select(state => state.roles);
  }

  selectIsInRole(role: string): Observable<boolean> {
    return this.select(state => state.roles.includes(role));
  }

  selectAccessToken(): Observable<string> {
    return this.select(state => state.accessToken);
  }

  selectIdToken(): Observable<string> {
    return this.select(state => state.idToken);
  }

  selectIsLoggedIn(): Observable<boolean> {
    return this.select(state => state.isLoggedIn);
  }

  selectDepartmentId(): Observable<string> {
    return this.select(state => state.departmentId);
  }

  selectZoneId(): Observable<string> {
    return this.select(state => state.zoneId);
  }

  getIsInRole(role: string): boolean {
    return this.getValue().roles.includes(role);
  }

  getUserFirmId(): string {
    return this.getValue().userFirmId;
  }

  getZoneId(): number {
    const zoneId = this.getValue().zoneId;
    return zoneId ? parseInt(zoneId, 10) : 0;
  }

}
