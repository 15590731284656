import { Component, Input, OnInit } from '@angular/core';
import { WindowService } from 'src/app/services/openfin/window.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  @Input() title: string = '';
  @Input() transparentBackground: boolean = false;

  constructor(
    private win: WindowService,
  ) { }

  ngOnInit(): void {
  }

  minimize() {
    this.win.minimize();
  }

  close() {
    this.win.close();
  }

}
