import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { SystemService } from './services/openfin/system.service';
import { InterApplicationBusService } from './services/openfin/inter-application-bus.service';
import { ApiService } from './services/api.service';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { createOpenfinPayloadData, OpenfinPayload, OpenfinPayloadData } from './models/openfin-payload.model';
import { SessionService } from './login/state/session.service';
import { ChangePersonaService } from './change-persona/state/change-persona.service';
import { createChangePersona } from './change-persona/state/change-persona.model';
import { NavigationEnd, Router } from '@angular/router';
import { inOpenFin } from "@openfin/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  subscriptions: Subscription[] = [];
  consoleAppUuid: string;
  isOpenFinAvailable: boolean = false;
  isInfoHealthComponent$: Observable<boolean>;

  constructor(
    private system: SystemService,
    private iab: InterApplicationBusService,
    private auth: AuthService,
    private http: ApiService,
    private sessionService: SessionService,
    private changePersonaService: ChangePersonaService,
    private router: Router
  ) { }

  ngOnInit() {
    // Set Initial Change Persona Config
    this.changePersonaService.setChangePersona(
      createChangePersona(environment.changePersona)
    );

    // Check if on info/health endpoint
    this.isInfoHealthComponent$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map((event: any) => event.url === '/info' || event.url === '/health')
    );

    // Check if opened in openfin
    this.isOpenFinAvailable = inOpenFin;
  }

  downloadOpenFin(): void {
    const host: string = encodeURI(environment.ingressHost);
    const url = `https://install.openfin.co/download/?os=win&config=${host}%2Fapp.json&fileName=eXate&unzipped=true`;
    window.location.href = url;
  }

  async ngAfterViewInit() {
    if (this.isOpenFinAvailable) {
      // Launch Console App
      // this.launchConsoleApp();

      // Get Encrypted Data
      this.iab.subscribe('*', 'getEncryptedData', (response: string) => {
        // Log encrypted data to console
        if (environment.loggingEnabled) {
          console.log('Recieved Data From * on getEncryptedData', response.split(' '));
        }

        const data: OpenfinPayload = {
          openfinPayload: response
            .split(' ')
            .map(val => {
              const filterVal = val.replace(/["']/g, "");
              return createOpenfinPayloadData({
                EncryptedValue: filterVal,
                DecryptedValue: filterVal
              })
            })
        };


        this.http.getDecryptedData(data).pipe(
          tap(response => {
            let decryptedData: OpenfinPayloadData[] = response.Responses[0].ResponseJson.openfinPayload;
            decryptedData = decryptedData.map(data => {
              data.IsError = data.EncryptedValue === data.DecryptedValue
              return data;
            });
            this.iab.publish('setDecryptedData', decryptedData);
          })
        ).subscribe();

        // environment.payload.Body.StringToDecrypt = response;
        // this.iab.send(environment.consoleApp.uuid, environment.consoleApp.topic, environment.payload);
      });

      // Send Environment Configuration
      this.iab.subscribe('*', 'getEnvConfig', (response: any) => {
        if (environment.loggingEnabled) {
          console.log('Recieved Data From * on getEnvConfig', response);
        }
        this.iab.send('*', 'setEnvConfig', {
          loggingEnabled: environment.loggingEnabled,
          dataRedaction: environment.dataRedaction,
          encryptedTextMockValue: environment.encryptedTextMockValue,
          interceptXHR: environment.interceptXHR
        });
      });
    }
  }

  // async launchConsoleApp() {
  //   this.consoleAppUuid = sessionStorage.getItem('consoleAppUuid');

  //   if (!this.consoleAppUuid) {
  //     if (environment.useConsoleApp) {
  //       environment.consoleApp.uuid = String(await this.system.launch('console-app'));
  //       sessionStorage.setItem('consoleAppUuid', environment.consoleApp.uuid);
  //     }
  //   } else {
  //     if (environment.loggingEnabled) {
  //       console.log(`console-app is running : ${this.consoleAppUuid}`);
  //     }
  //     environment.consoleApp.uuid = this.consoleAppUuid;
  //   }

  //   this.iab.subscribe(environment.consoleApp.uuid, environment.consoleApp.topic, (response: any) => {
  //     if (environment.loggingEnabled) {
  //       console.log('Recieved Data From ' + environment.consoleApp.uuid + ' on ' + environment.consoleApp.topic, response);
  //     }
  //     this.iab.publish('setDecryptedData', response.Body);
  //   });
  // }

}
