import { Injectable } from '@angular/core';
import { ChangePersona } from './change-persona.model';
import { ChangePersonaStore } from './change-persona.store';

@Injectable({ providedIn: 'root' })
export class ChangePersonaService {

  constructor(
    protected store: ChangePersonaStore
  ) { }

  setChangePersona(data: Partial<ChangePersona>): void {
    this.store.updateChangePersona(data);
  }

}
