// Core Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

// NGX-Bootstrap
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Routes
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';
import { ChangePersonaComponent } from './change-persona/change-persona.component';
import { CreateManifestComponent } from './create-manifest/create-manifest.component';
import { LaunchpadComponent } from './launchpad/launchpad.component';

// @datorama/akita
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';

// Environment
import { AuthInterceptor } from './services/auth/authconfig.interceptor';
import { environment } from '../environments/environment';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { InfoComponent } from './components/info/info.component';
import { HealthComponent } from './components/health/health.component';

let dev = [
  AkitaNgDevtools.forRoot(),
  AkitaNgRouterStoreModule
];

if (environment.production) {
  dev = [];
}

@NgModule({
  declarations: [
    AppComponent,
    ChangePersonaComponent,
    CreateManifestComponent,
    LaunchpadComponent,
    TopBarComponent,
    FooterComponent,
    LoginComponent,
    InfoComponent,
    HealthComponent
  ],
  imports: [
    ...dev,
    BrowserAnimationsModule,
    ButtonsModule,
    CollapseModule,
    BsDatepickerModule,
    PopoverModule,
    BsDropdownModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AkitaNgRouterStoreModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
