<app-top-bar title="Change Persona"></app-top-bar>

<div id="appSettings" class="wrapper">
    <form class="form-horizontal" role="form" [formGroup]="changePersonaForm">

        <div class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label text-light">
                Run as User
            </label>
            <div class="col-sm-6 input-group-sm">
                <label class="switch">
                    <input type="checkbox" formControlName="IsRunAsUserEnable">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>

        <div *ngIf="IsRunAsUserEnable.value" class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label text-light">
                User Name
            </label>
            <div class="col-sm-6 input-group-sm">
                <input type="text" class="form-control darkinput" formControlName="RunAsUserUniqueName">
                <ul *ngIf="RunAsUserUniqueName.invalid" class="parsley-errors-list filled">
                    <li *ngIf="RunAsUserUniqueName.errors.message" class="parsley-type">
                        {{RunAsUserUniqueName.errors.message}}</li>
                    <li *ngIf="RunAsUserUniqueName.touched && !RunAsUserUniqueName.errors.message" class="parsley-type">
                        User Name is required
                    </li>
                </ul>
            </div>
        </div>

        <div class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label text-light">
                Purpose of Use
            </label>
            <div class="col-sm-6 input-group-sm">
                <select class="form-control input-transparent custom-select darkinput" formControlName="DataUsageId">
                    <option *ngFor="let dataUsage of dataUsage$ | async" [value]="dataUsage.DataUsageId">
                        {{dataUsage.DataUsageName}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label text-light">
                Apply Localisation
            </label>
            <div class="col-sm-6 input-group-sm">
                <label class="switch">
                    <input type="checkbox" formControlName="ApplyDataLocalisation">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>

        <div class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label text-light">
                Requesting Country
            </label>
            <div class="col-sm-6 input-group-sm">
                <select class="form-control input-transparent custom-select darkinput" formControlName="CountryCode">
                    <option *ngFor="let country of countries$ | async" [value]="country.CountryId">
                        {{country.CountryName}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label text-light">
                Protect Null Values
            </label>
            <div class="col-sm-6 input-group-sm">
                <label class="switch">
                    <input type="checkbox" formControlName="ProtectNullValues">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>

        <div class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label text-light">
                Use Restricted Text
            </label>
            <div class="col-sm-6 input-group-sm">
                <label class="switch">
                    <input type="checkbox" formControlName="UseRestrictedText">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>

        <div *ngIf="UseRestrictedText.value" class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label text-light">
                Restricted Text
            </label>
            <div class="col-sm-6 input-group-sm">
                <input type="text" class="form-control darkinput" formControlName="RestrictedText">
                <ul *ngIf="RestrictedText.invalid" class="parsley-errors-list filled">
                    <li *ngIf="RestrictedText.errors.message" class="parsley-type">
                        {{RestrictedText.errors.message}}</li>
                    <li *ngIf="RestrictedText.touched && !RestrictedText.errors.message" class="parsley-type">
                        Restricted Text is required
                    </li>
                </ul>
            </div>
        </div>

        <div class="form-group row">
            <label for="staticEmail" class="col-sm-3 col-form-label text-light">
                Data Redaction
            </label>
            <div class="col-sm-6 input-group-sm">
                <label class="switch">
                    <input type="checkbox" formControlName="IsDataRedactionEnable">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>

    </form>

    <div class="form-group row px-3 mt-5">
      <div class="col">
        <button type="button" class="btn btn-save savebtn btn-lg mb-2" *ngIf="changePersonaForm.dirty" [disabled]="changePersonaForm.invalid" (click)="savePersonaChanges()">
            <i class="fa fa-check fa-darkicon bg-black"></i>Apply Changes
        </button>

        <button type="button" class="btn btn-save savebtn btn-lg mb-2" [routerLink]="'/launchpad'">
            <i class="fa fa-arrow-left fa-darkicon bg-black"></i>Go back
        </button>
      </div>
    </div>
</div>

<app-footer></app-footer>