import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LaunchpadComponent } from './launchpad/launchpad.component';
import { ChangePersonaComponent } from './change-persona/change-persona.component';
import { CreateManifestComponent } from './create-manifest/create-manifest.component';
import { LoginComponent } from './login/login.component';
import { HealthComponent } from './components/health/health.component';
import { InfoComponent } from './components/info/info.component';


const routes: Routes = [
  {
    path: 'launchpad',
    component: LaunchpadComponent
  },
  {
    path: 'change-persona',
    component: ChangePersonaComponent
  },
  {
    path: 'create-manifest',
    component: CreateManifestComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'health',
    component: HealthComponent,
  },
  {
    path: 'info',
    component: InfoComponent,
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
