import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { ChangePersonaService } from 'src/app/change-persona/state/change-persona.service';
import { ApiService } from 'src/app/services/api.service';
import { DataUsage } from './data-usage.model';
import { DataUsageQuery } from './data-usage.query';
import { DataUsageStore } from './data-usage.store';

@Injectable({ providedIn: 'root' })
export class DataUsageService {

  constructor(
    protected store: DataUsageStore,
    protected query: DataUsageQuery,
    protected http: ApiService,
    private changePersonaService: ChangePersonaService
  ) { }

  get(): Observable<DataUsage[]> {
    return this.query.selectHasCache().pipe(
      switchMap(hasCache => {
        const request = this.http.getDataUsageByFirmId().pipe(
          catchError(error => {
            // this.messageService.handleError('error getting data usages:', error);
            console.error('error getting data usages:', error);
            return EMPTY;
          }),
          tap(response => {
            this.store.set(response);
            if (response.length > 0) {
              this.setDataUsageId(response[0].DataUsageId);
            }
          })
        );
        return hasCache ? of([]) : request;
      }), delay(0)
    );
  }

  setDataUsageId(id: number): void {
    this.changePersonaService.setChangePersona({
      DataUsageId: id
    });
  }

}
