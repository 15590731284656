<ng-container *ngIf="(isInfoHealthComponent$ | async) === true; else defaultTemplate">
    <router-outlet></router-outlet>
</ng-container>

<ng-template #defaultTemplate>
    <ng-container *ngIf="!isOpenFinAvailable; else mainTemplate">
        <button (click)="downloadOpenFin()" type="button" class="btn btn-save savebtn btn-lg mb-2">
            <i class="fa fa-download fa-darkicon bg-black"></i>Download
        </button>
    </ng-container>

    <ng-template #mainTemplate>
        <router-outlet></router-outlet>
    </ng-template>
</ng-template>